import React, { PropsWithChildren } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Markdown from "../components/Markdown";
import { PageGraphQL } from ".";

const EmbeddedPage: React.FunctionComponent<PageGraphQL> = (props: PropsWithChildren<PageGraphQL>) => {
    const { data } = props;

    return (
        <Layout>
            <SEO title="Power BI Embedded Analytics" />
            <Markdown htmlAst={data.markdownRemark.htmlAst} />
        </Layout>
    );
};

export const query = graphql`
    query ($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            htmlAst
        }
    }
`;

export default EmbeddedPage;
